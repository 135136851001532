
    import {AppConsts} from "@/abpPro/AppConsts";
    import ImageUtils from "@/shared/utils/image-utils";
    import {fileDownloadService, localizationService} from "@/shared";
    import $ from 'jquery'

    export default {
        name: "UploadFile",
        props: {
        	/**
             * 文件列表
             */
	        fileList: {
	        	type: Array,
                default: []
            },
	        /**
             * 是否使用插槽
	         */
	        useSlot: {
        		type: Boolean,
                default: false
            },
	        /**
             * 是否以弹窗形式显示
	         */
	        useDialog: {
	        	type: Boolean,
                default: false
            }
        },
        data(){
        	return {
		        _fileDownloadService: null,

		        uploadPictureUrl:
			        AppConsts.remoteServiceBaseUrl +
			        "/Profile/UploadFilePicture",
		        uploadHeaders: {},
                filePath: AppConsts.remoteServiceBaseUrl+'/QYB/File/'+abp.session.tenantId+'/',
		        showDialog: false,
            }
        },
        created() {
	        this._fileDownloadService = fileDownloadService;
	        Object.assign(this.uploadHeaders, {
		        Authorization: "Bearer " + abp.auth.getToken()
	        });
        },
	    methods: {
		    l(key, ...args) {
			    return localizationService.l(key, ...args);
		    },
		    beforeUpload(file) {
			    const isLtXM = file.size / 1024 / 1024 < AppConsts.maxProFileMb;
			    if (!isLtXM) {
			    	abp.message.error('上传的文件不能大于'+AppConsts.maxProFileMb+'MB')
			    }
			    return isLtXM;
		    },
	        handleChange({file, fileList}){
		        // console.log(file)
		        this.$emit('update:fileList', fileList);
                //this.fileList = fileList;
                if(file.status === 'done'){
                	let suffix = file.name.substring(file.name.lastIndexOf('.')+1);

	                this.$emit('UploadDone', file, fileList, suffix);
                }else if(file.status === 'removed'){
	                let suffix = file.name.substring(file.name.lastIndexOf('.')+1);
	                this.$emit('UploadRemoved', file, fileList, suffix);
                }
            },
            async handlePreview(file){
                if(file.url){
                    this.handleDownload(file.url);
                }else{
                    //图片预览
                    if(file.type.indexOf('image') != -1){
                        ImageUtils.showFullImage(this.filePath + file.response.result.fileName);
                    }else{//文件下载
                        //this._fileDownloadService.downloadTempFile(file);
                        //window.location.href = this.filePath + file.response.result.fileName;
                        this.handleDownload(this.filePath + file.response.result.fileName);
                    }
                }
            },
		    handleDownload(url){
			    let a = document.createElement("a");
			    a.download = new Date().getTime() + ".xls";
			    a.href = url;
			    a.target = '_blank';
			    $("body").append(a); // 修复firefox中无法触发click
			    a.click();
			    $(a).remove();
		    }
        }
    }
