
import { AppComponentBase, EmployeeServiceProxy, EmployeeEditDto } from '@/shared';
import Dic from '@/shared/utils/dic';
import { AppConsts } from '@/abpPro/AppConsts';
import ImageUtils from '@/shared/utils/image-utils';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';

export default {
	name: 'personal-info',
	mixins: [AppComponentBase],
	props: {
		employee: {
			type: EmployeeEditDto,
			default: () => new EmployeeEditDto(),
		},
	},
	data() {
		return {
			//民族字典
			dicNations: [],
			zh_CN,
			//婚姻状况字典
			dicMaritalStatus: [],
			//政治面貌字典
			dicPoliticsStatus: [],
			//户口性质字典
			dicResidenceType: [],
			//学历字典
			dicEducation: [],
			//性别
			listgender: [],
			// 身份证有效日期
			identityValidity: undefined,

			// 图片上传相关 ↓↓↓
			//上传的地址
			uploadPictureUrl: AppConsts.remoteServiceBaseUrl + '/Profile/UploadProfilePictureReturnFileId',
			//员工相关图片地址
			empFilePath: `${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/UserPicture/File/${abp.session.tenantId}/`,
			//是否显示图片预览框
			previewVisible: false,
			//预览图片地址
			previewImage: '',

			//身份证人像面上传列表
			identityFaceImgFileList: [],
			//身份证人像面上传头
			identityFaceImgUploadHeaders: {
				Authorization: 'Bearer ' + abp.auth.getToken(),
				type: 'photo',
			},

			//身份证国徽面上传列表
			IdentityEmblemImgFileList: [],
			//身份证国徽面上传头
			IdentityEmblemImgUploadHeaders: {
				Authorization: 'Bearer ' + abp.auth.getToken(),
				type: 'photo',
			},
			// 图片上传相关 ↑↑↑
			InsuranceTypeList: [],
			insuranceType: undefined,
		};
	},
	async created() {
		try {
			this.loading = true;
			this.dicNations = await Dic.getInstance().getDicAsync('Nation');
			this.dicMaritalStatus = await Dic.getInstance().getDicAsync('MaritalStatus');
			this.dicPoliticsStatus = await Dic.getInstance().getDicAsync('PoliticsStatus');
			this.dicResidenceType = await Dic.getInstance().getDicAsync('ResidenceType');
			this.dicEducation = await Dic.getInstance().getDicAsync('Education');
			this.listgender = await Dic.getInstance().getDicAsync('Gender');
			this.InsuranceTypeList = await Dic.getInstance().getDicAsync('QYB_InsuranceType');

			this.identityValidity = this.employee.identityValidity;
			//初始化身份证人像面
			if (this.employee.identityFaceImg) {
				this.identityFaceImgFileList = [
					{
						uid: -1,
						name: this.employee.identityFaceImg,
						status: 'done',
						url: this.empFilePath + this.employee.identityFaceImg,
					},
				];
			}
			//初始化身份证国徽面
			if (this.employee.identityEmblemImg) {
				this.IdentityEmblemImgFileList = [
					{
						uid: -1,
						name: this.employee.identityEmblemImg,
						status: 'done',
						url: this.empFilePath + this.employee.identityEmblemImg,
					},
				];
			}
		} catch (e) {
		} finally {
			this.loading = false;
			this.isReady = true;
		}
	},
	methods: {
		/**
		 * 身份证有效日期变更
		 */
		identityValidityChange(e) {
			// this.employee.identityValidity = e;
			// this.identityValidity = e;
		},
		/**
		 * 身份证是否长期变更
		 */
		cardIsLongTermChange(e) {
			this.employee.identityValidity = undefined;
			// this.employee.identityStartDate=undefined;
			// this.identityValidity = undefined
		},
		/**
		 * 上传图片前
		 */
		beforeUpload(file) {
			const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
			if (!isJPG) {
				abp.message.error(this.l('OnlySupportPictureFile'));
			}
			const isLtXM = file.size / 1024 / 1024 < 5;
			if (!isLtXM) {
				abp.message.error(this.l('ProfilePicture_Warn_SizeLimit', 2));
			}
			return isJPG && isLtXM;
		},
		/**
		 * 证件照上传
		 */
		handlePictureChange({ file, fileList }, type) {
			console.log('file', file);
			if (type === 'face') {
				//人面照
				if (file.status === 'done') {
					this.employee.identityFaceImg = file.response.result.fileName;
				}
				if (file.status === 'removed') {
					this.employee.identityFaceImg = undefined;
				}
				this.identityFaceImgFileList = fileList;
			} else {
				//国徽照
				if (file.status === 'done') {
					this.employee.identityEmblemImg = file.response.result.fileName;
				}
				if (file.status === 'removed') {
					this.employee.identityEmblemImg = undefined;
				}
				this.IdentityEmblemImgFileList = fileList;
			}
		},
		/**
		 * 图片预览
		 */
		async handlePreview(file) {
			if (!file.url && !file.preview) {
				file.preview = await ImageUtils.getBase64(file.originFileObj);
			}
			this.previewImage = file.url || file.preview;
			this.previewVisible = true;
		},
	},
};
