
import { ModalComponentBase } from '../../../../../shared/component-base';
import { AnnexesFileEditDto, CreateOrUpdateSkillInfoInput, SkillInfoEditDto, SkillInfoServiceProxy } from '@/shared';
import Dic from '@/shared/utils/dic';
import UploadFile from '@/components/tools/UploadFile.vue';
import { AppConsts } from '@/abpPro/AppConsts';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';

export default {
	name: 'create-or-edit-skill-info',
	mixins: [ModalComponentBase],
	components: { UploadFile },
	data() {
		return {
			form: this.$form.createForm(this),
			id: undefined,
			spinning: false,
			zh_CN,
			UserId: undefined,
			entity: new SkillInfoEditDto(),
			SkillType: [],
			SkillLevel: [],
			//上传控件绑定列表
			FileList: [],
			//提交后台的附件集合
			AnnexesFileList: [],
			_SkillInfoServiceProxy: '',
			filepath: `${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/File/${abp.session.tenantId}/`,
		};
	},
	async created() {
		this.fullData();
		this._SkillInfoServiceProxy = new SkillInfoServiceProxy(this.$apiUrl, this.$api);
		this.SkillType = await Dic.getInstance().getDicAsync('SkillType');
		this.SkillLevel = await Dic.getInstance().getDicAsync('SkillLevel');
		if (this.id) {
			this.getData();
		}
	},
	methods: {
		getData() {
			this.spinning = true;
			this._SkillInfoServiceProxy
				.getForEdit(this.id)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					if (res) {
						this.entity = res.skillInfo;
						this.AnnexesFileList = res.annexesFile;
						//绑定页面显示附件
						res.annexesFile.forEach((item) => {
							this.FileList.push({
								uid: item.id,
								name: item.fileName,
								status: 'done',
								url: this.filepath + item.locName + '.' + item.fileType,
							});
						});
					}
				});
		},
		/**
		 * 上传
		 * @param file
		 * @param fileList
		 * @param type
		 * @constructor
		 */
		UploadData(file, fileList, type) {
			const filedto = new AnnexesFileEditDto();
			filedto.id = file.uid;
			filedto.folderId = file.uid;
			filedto.fileName = file.name;
			filedto.fileType = type;
			filedto.locName = file.response.result.fileId;
			this.AnnexesFileList.push(filedto);
		},
		/**
		 * 移除文件
		 * @param file
		 * @param fileList
		 * @param type
		 */
		removed(file, fileList, type) {
			this.AnnexesFileList = [...this.AnnexesFileList.filter((item) => item.id != file.uid)];
		},
		save() {
			if (!this.UserId) {
				return abp.message.warn('用户未知');
			}
			//属性值修改
			this.AnnexesFileList.map((item) => {
				item.folderId = item.folderId ? undefined : undefined;
				item.id = item.id ? undefined : undefined;
			});
			const input = new CreateOrUpdateSkillInfoInput();
			this.entity.empId = this.UserId;
			input.skillInfo = SkillInfoEditDto.fromJS(this.entity);
			input.annexesFile = this.AnnexesFileList;
			this.spinning = true;
			this._SkillInfoServiceProxy
				.createOrUpdate(input)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
	},
};
