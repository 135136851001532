<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="loading">
            <!-- 标题 -->
            <div class="modal-header">
                <div class="modal-title">
                    <span style="margin-left: 5px;" v-if="EmpId && isReady && type == 0">
                        <a-icon type="user" />
                        {{ employee.realName ? employee.realName : ''
                        }}{{ employee.jobNumber ? `(${employee.jobNumber})` : '' }}
                    </span>
                    <span v-if="isReady && type == 0">
                        {{ EmpId ? '信息' : '添加员工' }}
                    </span>
                    <span v-if="type == 1">
                        办理入职
                    </span>
                </div>
            </div>
            <a-config-provider :locale="zh_CN">
                <!-- tab切换 -->
                <a-tabs :defaultActiveKey="0" :tab-position="'top'" @change="tabChange" v-if="isReady">
                    <!-- 基础信息 -->
                    <a-tab-pane :key="0" :tab="l('基础信息')">
                        <basic-info
                            @createAccountChange="AccountChange"
                            @originalJobNumber="originalJobNumber"
                            :employee="employee"
                            :type="type"
                            :issave="issave"
                            ref="basic"
                            :user="user"
                        >
                        </basic-info>
                    </a-tab-pane>
                    <!-- 工作信息 -->
                    <a-tab-pane :key="6" v-if="isShowTab" tab="工作信息">
                        <work-info :employee="employee"></work-info>
                    </a-tab-pane>
                    <!-- 个人信息 -->
                    <a-tab-pane :key="7" v-if="isShowTab" tab="个人信息">
                        <personal-info :employee="employee"></personal-info>
                    </a-tab-pane>
                    <!-- 合同信息 -->
                    <a-tab-pane :key="1" v-if="isShowTab" :tab="l('PersonnelContracts')">
                        <div>
                            <a-button :type="'primary'" @click="createOrEditPersonnelContracts()">
                                <a-icon type="plus" />
                                {{ l('Create') }}
                            </a-button>
                        </div>
                        <a-table
                            :columns="personnelContractsColumns"
                            :dataSource="personnelContractsTableData"
                            :pagination="false"
                            :rowKey="(tableDatas) => tableDatas.id"
                            :scroll="{ x: 1300, y: 360 }"
                        >
                            <!-- 数据操作 -->
                            <span slot="actions" slot-scope="text, record">
                                <!-- 修改 -->
                                <a class="table-edit" @click="createOrEditPersonnelContracts(record.id)">
                                    <a-icon type="edit" />{{ l('Edit') }}
                                </a>
                                <!-- 删除 -->
                                <a-popconfirm
                                    placement="top"
                                    :okText="l('Ok')"
                                    :cancelText="l('Cancel')"
                                    @confirm="deletePersonnelContracts(record)"
                                >
                                    <template slot="title">
                                        {{ l('ConfirmDeleteWarningParamMessage', record.pcNumber) }}
                                    </template>
                                    <a class="table-delete" style="color: red;">
                                        <a-icon type="delete" />{{ l('Delete') }}
                                    </a>
                                </a-popconfirm>
                            </span>
                        </a-table>
                    </a-tab-pane>
                    <!-- 社会关系 -->
                    <a-tab-pane :key="2" v-if="isShowTab" :tab="l('紧急联系人')">
                        <div>
                            <a-button :type="'primary'" @click="createOrEditSocialRelation()">
                                <a-icon type="plus" />
                                {{ l('Create') }}
                            </a-button>
                        </div>
                        <a-table
                            :columns="SocialRelationColumns"
                            :dataSource="SocialRelationTableData"
                            :pagination="false"
                            :rowKey="(tableDatas) => tableDatas.id"
                            :scroll="{ x: 1300, y: 360 }"
                        >
                            <!-- 数据操作 -->
                            <span slot="actions" slot-scope="text, record">
                                <!-- 修改 -->
                                <a class="table-edit" @click="createOrEditSocialRelation(record.id)">
                                    <a-icon type="edit" />{{ l('Edit') }}
                                </a>
                                <!-- 删除 -->
                                <a-popconfirm
                                    placement="top"
                                    :okText="l('Ok')"
                                    :cancelText="l('Cancel')"
                                    @confirm="deleteSocialRelation(record)"
                                >
                                    <template slot="title">
                                        {{ l('ConfirmDeleteWarningMessage') }}
                                    </template>
                                    <a class="table-delete" style="color: red;">
                                        <a-icon type="delete" />{{ l('Delete') }}
                                    </a>
                                </a-popconfirm>
                            </span>
                        </a-table>
                    </a-tab-pane>
                    <!--工作经验-->
                    <a-tab-pane :key="3" v-if="isShowTab" :tab="l('WorkExperience')">
                        <div>
                            <a-button
                                :type="'primary'"
                                v-if="currentTabKey === 3"
                                @click="createOrEditWorkExperience()"
                            >
                                <a-icon type="plus" />
                                {{ l('Create') }}
                            </a-button>
                        </div>
                        <a-table
                            :columns="WorkExperienceColumns"
                            :dataSource="WorkExperienceTableData"
                            :pagination="false"
                            :rowKey="(tableDatas) => tableDatas.id"
                            :scroll="{ x: 1300, y: 360 }"
                        >
                            <!-- 数据操作 -->
                            <span slot="actions" slot-scope="text, record">
                                <!-- 修改 -->
                                <a class="table-edit" @click="createOrEditWorkExperience(record.id)">
                                    <a-icon type="edit" />{{ l('Edit') }}
                                </a>
                                <!-- 删除 -->
                                <a-popconfirm
                                    placement="top"
                                    :okText="l('Ok')"
                                    :cancelText="l('Cancel')"
                                    @confirm="deleteWorkExperience(record)"
                                >
                                    <template slot="title">
                                        {{ l('ConfirmDeleteWarningMessage') }}
                                    </template>
                                    <a class="table-delete" style="color: red;">
                                        <a-icon type="delete" />{{ l('Delete') }}
                                    </a>
                                </a-popconfirm>
                            </span>
                        </a-table>
                    </a-tab-pane>
                    <!--教育背景-->
                    <a-tab-pane :key="4" v-if="isShowTab" :tab="l('EducationBackground')">
                        <div>
                            <a-button :type="'primary'" @click="createOrEditEducationBackground()">
                                <a-icon type="plus" />
                                {{ l('Create') }}
                            </a-button>
                        </div>
                        <a-table
                            :columns="EducationBackgroundColumns"
                            :dataSource="EducationBackgroundTableData"
                            :pagination="false"
                            :rowKey="(tableDatas) => tableDatas.id"
                            :scroll="{ x: 1500, y: 360 }"
                        >
                            <!-- 数据操作 -->
                            <span slot="actions" slot-scope="text, record">
                                <!-- 修改 -->
                                <a class="table-edit" @click="createOrEditEducationBackground(record.id)">
                                    <a-icon type="edit" />{{ l('Edit') }}
                                </a>
                                <!-- 删除 -->
                                <a-popconfirm
                                    placement="top"
                                    :okText="l('Ok')"
                                    :cancelText="l('Cancel')"
                                    @confirm="deleteEducationBackground(record)"
                                >
                                    <template slot="title">
                                        {{ l('ConfirmDeleteWarningMessage') }}
                                    </template>
                                    <a class="table-delete" style="color: red;">
                                        <a-icon type="delete" />{{ l('Delete') }}
                                    </a>
                                </a-popconfirm>
                            </span>
                        </a-table>
                    </a-tab-pane>
                    <!--证书信息-->
                    <a-tab-pane :key="5" v-if="isShowTab" :tab="l('SkillInfo')">
                        <div>
                            <a-button :type="'primary'" @click="createOrEditSkillInfo()">
                                <a-icon type="plus" />
                                {{ l('Create') }}
                            </a-button>
                        </div>
                        <a-table
                            :columns="SkillInfoColumns"
                            :dataSource="SkillInfoTableData"
                            :pagination="false"
                            :rowKey="(tableDatas) => tableDatas.id"
                            :scroll="{ x: 1500, y: 360 }"
                        >
                            <!-- 数据操作 -->
                            <span slot="actions" slot-scope="text, record">
                                <!-- 修改 -->
                                <a class="table-edit" @click="createOrEditSkillInfo(record.id)">
                                    <a-icon type="edit" />{{ l('Edit') }}
                                </a>
                                <!-- 删除 -->
                                <a-popconfirm
                                    placement="top"
                                    :okText="l('Ok')"
                                    :cancelText="l('Cancel')"
                                    @confirm="deleteSkillInfo(record)"
                                >
                                    <template slot="title">
                                        {{ l('ConfirmDeleteWarningMessage') }}
                                    </template>
                                    <a class="table-delete" style="color: red;">
                                        <a-icon type="delete" />{{ l('Delete') }}
                                    </a>
                                </a-popconfirm>
                            </span>
                        </a-table>
                    </a-tab-pane>
                </a-tabs>
                <a-skeleton v-else active />
            </a-config-provider>
            <!-- 按钮 -->
            <div class="modal-footer" style="text-align: center;">
                <a-row>
                    <a-col :span="4">
                        <a-checkbox v-if="isbtn" @change="saveAndContinue" :checked="IssaveAndContinue">
                            保存并继续
                        </a-checkbox>
                    </a-col>
                    <a-col :span="16" style="text-align: center">
                        <a-button
                            :type="'primary'"
                            v-if="currentTabKey === 0 || currentTabKey === 6 || currentTabKey === 7"
                            @click="saveUserInfo()"
                        >
                            <a-icon :type="saving ? 'loading' : 'save'" />
                            {{ l('Save') }}
                        </a-button>
                    </a-col>
                </a-row>
            </div>
        </a-spin>
    </a-config-provider>
</template>
<!--点击员工名 查看页面-->
<script>
import { ModalComponentBase } from '@/shared/component-base';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN.js';
import {
    PersonnelContractsServiceProxy,
    SocialRelationServiceProxy,
    WorkExperienceServiceProxy,
    EducationBackgroundServiceProxy,
    SkillInfoServiceProxy,
    UserEditDto,
    EmployeeServiceProxy,
    EmployeeEditDto,
    CreateOrUpdateEmployeeInput,
} from '../../../../shared/service-proxies';

import CreateOrEditPersonnelContractsComponent from './create-or-edit-personnel-contracts/create-or-edit-personnel-contracts.vue';
import UploadFile from '@/components/tools/UploadFile.vue';
import CreateOrEditSocialRelation from './create-or-edit-social-relation/create-or-edit-social-relation.vue';
import CreateOrEditWorkExperience from './create-or-edit-work-experience/create-or-edit-work-experience.vue';
import CreateOrEditEducationBackground from './create-or-edit-education-background/create-or-edit-education-background.vue';
import CreateOrEditSkillInfo from './create-or-edit-skill-info/create-or-edit-skill-info.vue';
import CreateOrEditViewUser from '@/app/admin/users/view-user/create-or-edit-view-user/create-or-edit-view-user.vue';
import BasicInfo from './basic-info/basic-info.vue';
import WorkInfo from './work-info/work-info.vue';
import PersonalInfo from './personal-info/personal-info.vue';

import { ModalHelper } from '@/shared';
import moment from 'moment';
import ImageUtils from '@/shared/utils/image-utils';
import ObjectUtils from '@/shared/utils/ObjectUtils';
import Dic from '../../../../shared/utils/dic';
import { CreateOrEditHandLenntryInput, HandLeentryServiceProxy } from '../../../../shared/service-proxies';
import { AppConsts } from '../../../../abpPro/AppConsts';
import apiHttpClient from '../../../../shared/utils/api-http-client';
import Reg from '../../../../shared/utils/reg';

export default {
    name: 'view-user',
    components: {
        CreateOrEditViewUser,
        CreateOrEditPersonnelContractsComponent,
        CreateOrEditSocialRelation,
        CreateOrEditWorkExperience,
        CreateOrEditEducationBackground,
        CreateOrEditSkillInfo,
        UploadFile,
        BasicInfo,
        WorkInfo,
        PersonalInfo,
    },
    mixins: [ModalComponentBase],
    data() {
        return {
            fileList: [],
            zh_CN,
            _personnelContractsServiceProxy: null,
            //合同信息列结构
            personnelContractsColumns: [
                {
                    title: this.l('PcNumber'),
                    dataIndex: 'pcNumber',
                    key: 'pcNumber',
                    ellipsis: true,
                },
                {
                    title: this.l('PcType'),
                    dataIndex: 'pcTypeName',
                    key: 'pcTypeId',
                    ellipsis: true,
                },
                {
                    title: this.l('PcStartDate'),
                    dataIndex: 'startDate',
                    key: 'startDate',
                    ellipsis: true,
                },
                {
                    title: this.l('PcEndDate'),
                    dataIndex: 'endDate',
                    key: 'endDate',
                    ellipsis: true,
                },
                {
                    title: this.l('PcIsNotHaveFixedTerm'),
                    dataIndex: 'isNotHaveFixedTermName',
                    key: 'isNotHaveFixedTermId',
                    ellipsis: true,
                },
                {
                    title: this.l('PcDueTerm'),
                    dataIndex: 'dueTerm',
                    key: 'dueTerm',
                    ellipsis: true,
                },
                {
                    title: this.l('PcSignedMode'),
                    dataIndex: 'signedModeName',
                    key: 'signedModeId',
                    ellipsis: true,
                },
                {
                    title: this.l('PcIsComplete'),
                    dataIndex: 'isCompleteName',
                    key: 'isCompleteId',
                    ellipsis: true,
                },
                {
                    title: this.l('PcFilesAddress'),
                    dataIndex: 'filesAddress',
                    key: 'filesAddress',
                    ellipsis: true,
                },
                {
                    title: this.l('Remark'),
                    dataIndex: 'description',
                    key: 'description',
                    ellipsis: true,
                },
                {
                    title: this.l('Actions'),
                    dataIndex: 'actions',
                    align: 'center',
                    fixed: 'right',
                    scopedSlots: { customRender: 'actions' },
                    width: '120px',
                },
            ],
            //合同信息列数据
            personnelContractsTableData: [],
            //上个页面的传值
            //用户实体
            // user: new PersonnelContractsListDto(),
            //主页面传参
            EmpId: undefined,
            //公司ID
            companyId: undefined,
            //部门ID
            depId: undefined,

            employee: new EmployeeEditDto(),
            user: new UserEditDto(),

            //社会关系表数据
            SocialRelationTableData: [],
            //社会关系列机构
            SocialRelationColumns: [
                {
                    title: this.l('SocialRelationRelation'),
                    dataIndex: 'relation',
                    key: 'relation',
                    ellipsis: true,
                    align: 'center',
                    width: 120,
                },
                {
                    title: this.l('SocialRelationName'),
                    dataIndex: 'name',
                    key: 'name',
                    ellipsis: true,
                    align: 'center',
                    width: 120,
                },
                {
                    title: this.l('SocialRelationRelatioAddress'),
                    dataIndex: 'relatioAddress',
                    key: 'relatioAddress',
                    ellipsis: true,
                    align: 'center',
                    width: 150,
                },
                {
                    title: this.l('SocialRelationPhone'),
                    dataIndex: 'phone',
                    key: 'phone',
                    ellipsis: true,
                    align: 'center',
                    width: 150,
                },
                {
                    title: this.l('IsUrgentEmpl'),
                    dataIndex: 'isUrgentEmpName',
                    key: 'itemDetailName',
                    ellipsis: true,
                    align: 'center',
                    width: 150,
                },
                {
                    title: this.l('SocialRelationDescription'),
                    dataIndex: 'description',
                    key: 'description',
                    ellipsis: true,
                    align: 'center',
                    width: 150,
                },
                {
                    title: this.l('Actions'),
                    dataIndex: 'actions',
                    align: 'center',
                    fixed: 'right',
                    scopedSlots: { customRender: 'actions' },
                    width: '120px',
                },
            ],
            //工作经验表格数据
            WorkExperienceTableData: [],
            //工作经验列结构
            WorkExperienceColumns: [
                {
                    title: this.l('WECompanyName'),
                    dataIndex: 'companyName',
                    key: 'companyName',
                    ellipsis: true,
                },
                {
                    title: this.l('PostName'),
                    dataIndex: 'postName',
                    key: 'postName',
                    ellipsis: true,
                },
                {
                    title: this.l('WEStartDate'),
                    dataIndex: 'startDate',
                    key: 'startDate',
                    ellipsis: true,
                },
                {
                    title: this.l('WEEndDate'),
                    dataIndex: 'endDate',
                    key: 'endDate',
                    ellipsis: true,
                },
                {
                    title: this.l('Salary'),
                    dataIndex: 'salary',
                    key: 'salary',
                    ellipsis: true,
                },
                {
                    title: this.l('CertificateEmpl'),
                    dataIndex: 'certificateEmpl',
                    key: 'certificateEmpl',
                    ellipsis: true,
                },
                {
                    title: this.l('CertificateEmplTel'),
                    dataIndex: 'certificateEmplTel',
                    key: 'certificateEmplTel',
                    ellipsis: true,
                },
                {
                    title: this.l('LeaveJobReason'),
                    dataIndex: 'leaveJobReason',
                    key: 'leaveJobReason',
                    ellipsis: true,
                },
                {
                    title: this.l('WorkDesc'),
                    dataIndex: 'workDesc',
                    key: 'workDesc',
                    ellipsis: true,
                },
                {
                    title: this.l('Actions'),
                    dataIndex: 'actions',
                    align: 'center',
                    fixed: 'right',
                    scopedSlots: { customRender: 'actions' },
                    width: '120px',
                },
            ],
            //教育背景表数据
            EducationBackgroundTableData: [],
            //教育背景列结构
            EducationBackgroundColumns: [
                {
                    title: this.l('EBStartDate'),
                    dataIndex: 'startDate',
                    key: 'startDate',
                    ellipsis: true,
                },
                {
                    title: this.l('EBEndDate'),
                    dataIndex: 'endDate',
                    key: 'endDate',
                    ellipsis: true,
                },
                {
                    title: this.l('SchoolName'),
                    dataIndex: 'schoolName',
                    width: '200px',
                    key: 'schoolName',
                    ellipsis: true,
                },
                {
                    title: this.l('Professional'),
                    dataIndex: 'professional',
                    key: 'professional',
                    ellipsis: true,
                },
                {
                    title: this.l('EduType'),
                    dataIndex: 'eduTypeName',
                    key: 'eduTypeName',
                    ellipsis: true,
                },
                {
                    title: this.l('Education'),
                    dataIndex: 'educationsName',
                    key: 'educationsName',
                    ellipsis: true,
                },
                {
                    title: this.l('Degree'),
                    dataIndex: 'degreesName',
                    key: 'degreesName',
                    ellipsis: true,
                },
                {
                    title: this.l('AcademicYearSystem'),
                    dataIndex: 'academicYearSystem',
                    key: 'academicYearSystem',
                    ellipsis: true,
                },
                {
                    title: this.l('IsHighestEducation'),
                    dataIndex: 'isHighestEducationName',
                    key: 'isHighestEducationName',
                    ellipsis: true,
                },
                {
                    title: this.l('IsFirstEdu'),
                    dataIndex: 'isFirstEduName',
                    key: 'isFirstEduName',
                    ellipsis: true,
                },
                {
                    title: this.l('IsNProject'),
                    dataIndex: 'isNProjectName',
                    key: 'isNProjectName',
                    ellipsis: true,
                },
                {
                    title: this.l('Actions'),
                    dataIndex: 'actions',
                    align: 'center',
                    fixed: 'right',
                    scopedSlots: { customRender: 'actions' },
                    width: '120px',
                },
            ],
            //证书信息列结构
            SkillInfoColumns: [
                {
                    title: this.l('SkillInfoName'),
                    dataIndex: 'name',
                    key: 'name',
                    ellipsis: true,
                },
                {
                    title: this.l('SkillType'),
                    dataIndex: 'skIllTypeName',
                    key: 'skIllTypeName',
                    ellipsis: true,
                },
                {
                    title: this.l('SkillLevel'),
                    dataIndex: 'skillLecelName',
                    key: 'skillLecelName',
                    ellipsis: true,
                },
                {
                    title: this.l('SkillInfoCertificateNumber'),
                    dataIndex: 'certificateNumber',
                    key: 'certificateNumber',
                    ellipsis: true,
                },
                {
                    title: this.l('SkillInfoPatentNumber'),
                    dataIndex: 'patentNumber',
                    key: 'patentNumber',
                    ellipsis: true,
                },
                {
                    title: this.l('ExpiryDate'),
                    dataIndex: 'expiryDate',
                    key: 'expiryDate',
                    ellipsis: true,
                },
                {
                    title: this.l('EffectiveDate'),
                    dataIndex: 'effectiveDate',
                    key: 'effectiveDate',
                    ellipsis: true,
                },
                {
                    title: this.l('SkillInfoDescription'),
                    dataIndex: 'description',
                    key: 'description',
                    ellipsis: true,
                },
                {
                    title: this.l('Actions'),
                    dataIndex: 'actions',
                    align: 'center',
                    fixed: 'right',
                    scopedSlots: { customRender: 'actions' },
                    width: '120px',
                },
            ],
            //证书信息表格数据
            SkillInfoTableData: [],
            //当前tabkey
            currentTabKey: 0,

            isShowTab: false,
            //账号相关
            //角色
            role: [],

            //工号规则相关字段 选择公司后获取工号规则，提交时验证员工实体里的工号是否和工号规则里面的工号是否一致
            //一致：提交的实体就传serialNumber
            //不一致：serialNumber传空，jobNumber赋值newEmpJobNumber
            newEmpJobNumber: '',
            serialNumber: '',
            //保存并继续
            IssaveAndContinue: false,
            isbtn: false,
            //0:员工模块 1:入职办理模块
            type: 0,
            employeeStateList: [],
            isSubscribed: false,
            //是否提交过
            issave: false,
            //上传的地址
            uploadPictureUrl: AppConsts.remoteServiceBaseUrl + '/Profile/UploadProfilePictureReturnFileId',
            //证件照上传头
            empUploadHeaders: {
                Authorization: 'Bearer ' + abp.auth.getToken(),
                type: 'photo',
                JobNumber: '',
            },
        };
    },
    created() {
        this.fullData();
        //合同信息
        this._personnelContractsServiceProxy = new PersonnelContractsServiceProxy(this.$apiUrl, this.$api);
        //社会关系
        this._socialRelationServiceProxy = new SocialRelationServiceProxy(this.$apiUrl, this.$api);
        //工作经验
        this._workExperienceServiceProxy = new WorkExperienceServiceProxy(this.$apiUrl, this.$api);
        //教育背景
        this._ducationBackgroundServiceProxy = new EducationBackgroundServiceProxy(this.$apiUrl, this.$api);
        //证书信息
        this._skillInfoServiceProxy = new SkillInfoServiceProxy(this.$apiUrl, this.$api);
        this._employeeServiceProxy = new EmployeeServiceProxy(this.$apiUrl, this.$api);
        this._handLeentryServiceProxy = new HandLeentryServiceProxy(this.$apiUrl, this.$api);

        this.getData();
    },
    async mounted() {
        this.employeeStateList = await Dic.getInstance().getDicAsync('EmpState');
    },
    methods: {
        getData() {
            this.isSubscribed = false;
            if (this.type == 0 && this.EmpId) {
                //获取合同信息列表
                this.getPersonnelContractsList();
                //显示其他页签
                this.isShowTab = true;
                //是否勾选保存并继续
                this.IssaveAndContinue = false;
                this.isbtn = false;
            }

            this.loading = true;
            this._employeeServiceProxy
                .getForEdit(this.EmpId)
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    this.role = res.roles;
                    this.employee = res.employee;
                    this.employee.isCreateUser = true;
                    if (!this.employee.companyId) {
                        this.employee.companyId = this.companyId;
                    }
                    let code = this.employeeStateList.find((item) => item.itemDetailCode == this.employee.empStateCode);
                    //入职办理部分特殊逻辑
                    if (code) {
                        if (this.type == 1 && code.itemDetailName == '待入职') {
                            //隐藏其他页签
                            this.isShowTab = false;
                            //是否勾选保存并继续
                            this.IssaveAndContinue = false;
                            this.isbtn = true;
                            this.isSubscribed = true;
                        }
                        if (this.type == 1 && code.itemDetailName != '待入职') {
                            //显示其他页签
                            this.isShowTab = true;
                            //是否勾选保存并继续
                            this.IssaveAndContinue = false;
                            this.isbtn = false;
                        }
                    }
                    if (!this.employee.departmentId) {
                        this.employee.departmentId = this.depId;
                    }
                    this.user = ObjectUtils.initDto(res.user);
                    if (!this.user.id && !this.EmpId) {
                        this.isbtn = true;
                    }
                    this.isReady = true;
                });
        },
        /**
         * 保存基础信息
         */
        async saveUserInfo() {
            if (this.employee.mobile) {
                this.employee.mobile = this.employee.mobile.trim();
            }

            if (await this.checkErr()) {
                if (await this.getEmpDepManager()) {
                    this.issave = false;
                    this.loading = true;
                    const input = new CreateOrUpdateEmployeeInput();
                    try {
                        if (this.type == 1 && this.isSubscribed) {
                            let code = this.employeeStateList.find((item) => item.itemDetailCode == '04');
                            this.employee.empStateCode = code ? code.itemDetailCode : undefined;
                        }
                        //员工模块添加时如果没有选择状态，默认在职
                        if (this.type == 0) {
                            if (!this.employee.empStateCode) {
                                let code = this.employeeStateList.find((item) => item.itemDetailCode == '01');
                                this.employee.empStateCode = code ? code.itemDetailCode : undefined;
                                this.employee.hireDate = moment();
                                this.employee.regularDate = moment();
                            }
                        }
                        input.employee = this.employee;
                        if (this.employee.isCreateUser) {
                            this.user.realName = this.employee.realName;
                        }
                        //判断用户是否勾选了创建账号，否的话传给后台空
                        input.user = this.employee.isCreateUser ? this.user : undefined;
                        //赋值权限的roleDisplayName
                        input.assignedRoleNames = this.role.reduce((arr, { isAssigned, roleDisplayName }) => {
                            if (isAssigned) arr.push(roleDisplayName);
                            return arr;
                        }, []);
                        //一致：提交的实体就传serialNumber
                        //不一致：serialNumber传空，jobNumber赋值newEmpJobNumber
                        if (input.employee.jobNumber == this.newEmpJobNumber) {
                            input.serialNumber = this.serialNumber;
                        } else {
                            input.serialNumber = '';
                        }
                    } catch (e) {
                        this.loading = false;
                        abp.message.error(JSON.stringify(e));
                        console.log(e);
                    }
                    // //入职提醒
                    // if (this.employee.identityCard && this.EmpId) {
                    //     let e = await this.checkIdCard();
                    //     if (e) {
                    //         abp.message.warn(e);
                    //         return;
                    //     }
                    // }
                    if (this.$refs.basic.empFileList.length > 0 && this.employee.jobNumber) {
                        const formData = new FormData();
                        this.$refs.basic.empFileList.forEach((file) => {
                            formData.append('file', file.originFileObj);
                        });
                        let file = formData.get('file');
                        if (file != 'undefined') {
                            input.employee.idPhoto = await this.uploadEmpIdoto(formData);
                        }
                    } else if (this.$refs.basic.empFileList <= 0) {
                        input.employee.idPhoto = undefined;
                    }
                    //如果已过转正日期且员工状态不是在职或者预离职
                    if (
                        input.employee.regularDate < moment() &&
                        (input.employee.probationMonth || input.employee.probationMonth == 0) &&
                        input.employee.empStateCode != '01' &&
                        input.employee.empStateCode != '03'
                    ) {
                        input.employee.empStateCode = '01';
                        this.$confirm({
                            title: '确认操作',
                            okText: '确认',
                            content: '该员工已达转正日期，系统已将员工状态更改为在职',
                        });
                    }
                    //为达转正日期状态不为试用或预离职的
                    else if (
                        input.employee.regularDate >= moment() &&
                        (input.employee.probationMonth || input.employee.probationMonth == 0) &&
                        input.employee.empStateCode != '04' &&
                        input.employee.empStateCode != '03'
                    ) {
                        input.employee.empStateCode = '04';
                        this.$confirm({
                            title: '确认操作',
                            okText: '确认',
                            content: '系统识别出该员工还在试用期，已将员工状态改为试用',
                        });
                    }
                    this.createOrupdateEmployee(input);
                }
            }
        },
        /**
         * 部门负责人人事异动操作
         */
        getEmpDepManager() {
            return new Promise((resolve) => {
                if (!this.employee.id || !this.employee.departmentId) {
                    resolve(true);
                } else {
                    this._employeeServiceProxy
                        .getEmpDepManager(this.employee.id, this.employee.departmentId)
                        .finally(() => {})
                        .then((res) => {
                            if (res.departmentId) {
                                this.$confirm({
                                    title: '确认操作',
                                    content:
                                        this.employee.realName +
                                        '为' +
                                        res.departmentName +
                                        '部门的负责人，部门异动前请变更正确的负责人',
                                    okText: '确认',
                                    cancelText: '取消',
                                    onOk: () => {
                                        ModalHelper.create(
                                            EditDepartmentManager,
                                            {
                                                id: res.departmentId,
                                            },
                                            {
                                                width: '500px',
                                            }
                                        ).subscribe((res) => {
                                            // if(res){
                                            //     resolve(true);
                                            // }
                                        });
                                    },
                                });
                            } else {
                                resolve(true);
                            }
                        });
                }
            });
        },
        /**
         * 添加或编辑员工
         */
        createOrupdateEmployee(input) {
            this._employeeServiceProxy
                .createOrUpdate(input)
                .finally(() => {
                    this.loading = false;
                })
                .then(async (res) => {
                    this.issave = true;
                    if (this.type == 1) {
                        //添加入职记录
                        let input = new CreateOrEditHandLenntryInput();
                        input.employee = this.employee;
                        this.loading = true;
                        this._handLeentryServiceProxy
                            .createOrUpdate(input)
                            .finally(() => {
                                this.loading = false;
                            })
                            .then((res) => {
                                if (this.IssaveAndContinue) {
                                    this.getData();
                                    this.$notification['success']({
                                        message: this.l('SavedSuccessfully'),
                                    });
                                } else {
                                    this.success(true);
                                    this.$notification['success']({
                                        message: this.l('SavedSuccessfully'),
                                    });
                                }
                            });
                    } else {
                        if (this.IssaveAndContinue) {
                            this.EmpId = res.id;
                            this.getData();
                            this.$notification['success']({
                                message: this.l('SavedSuccessfully'),
                            });
                        } else {
                            this.success(true);
                            this.$notification['success']({
                                message: this.l('SavedSuccessfully'),
                            });
                        }
                    }
                });
        },
        /**
         * 上传证件照
         */
        uploadEmpIdoto(formData) {
            return new Promise((resolve) => {
                this.issave = false;
                this.loading = true;

                this.empUploadHeaders.JobNumber = this.employee.jobNumber;
                apiHttpClient
                    .post(this.uploadPictureUrl, formData, {
                        headers: this.empUploadHeaders,
                    })
                    .finally(() => {
                        this.loading = false;
                    })
                    .then((res) => {
                        resolve(res.data.fileName);
                    });
            });
        },
        saveAndContinue(e) {
            this.IssaveAndContinue = e.target.checked;
        },
        /**
         *入职信息提醒：未成年、已退休、二进宫
         */
        checkIdCard() {
            return new Promise((resolve, reject) => {
                this.loading = true;
                this._employeeServiceProxy
                    .inductionReminder(this.employee.identityCard, this.EmpId)
                    .finally(() => {
                        this.loading = false;
                    })
                    .then((res) => {
                        resolve(res);
                    });
            });
        },
        /**
         * 校验表单
         */
        checkErr() {
            return new Promise((resolve, reject) => {
                if (!this.employee.companyId) {
                    abp.message.warn('公司不能为空');
                    resolve(false);
                } else if (!this.employee.departmentId) {
                    abp.message.warn('部门不能为空');
                    resolve(false);
                } else if (!this.employee.jobNumber) {
                    abp.message.warn('工号不能为空');
                    resolve(false);
                } else if (!this.employee.realName) {
                    abp.message.warn('姓名不能为空');
                    resolve(false);
                }
                if (this.employee.isCreateUser && !this.user.userName) {
                    abp.message.warn('请输入账号');
                    resolve(false);
                }
                if (this.employee.mobile) {
                    if (!Reg.getInstance().RegMobile(this.employee.mobile)) {
                        abp.message.warn('员工手机号格式错误');
                        resolve(false);
                    }
                }
                // if (!this.user.id && this.employee.isCreateUser) {
                // 	this.user.userName = this.employee.jobNumber;
                // 	this.user.password = "123456";
                // }
                resolve(true);
            });
        },
        /**
         * 选择公司后获取的工号规则（包含工号，工号流水号）
         */
        originalJobNumber({ newEmpJobNumber, serialNumber }) {
            //记录原始工号规则信息
            this.newEmpJobNumber = newEmpJobNumber;
            this.serialNumber = serialNumber;
        },

        /**
         * 获取合同信息列表
         */
        getPersonnelContractsList() {
            this.loading = true;
            this._personnelContractsServiceProxy
                .getListByUserId(this.EmpId)
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    this.personnelContractsTableData = res;
                    this.personnelContractsTableData.map((item) => {
                        item.startDate = item.startDate ? moment(item.startDate).format('YYYY-MM-DD') : '';
                        item.endDate = item.endDate ? moment(item.endDate).format('YYYY-MM-DD') : '';
                    });
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        /**
         * 新增或编辑用户合同
         * @param id 合同id
         */
        createOrEditPersonnelContracts(id) {
            ModalHelper.create(
                CreateOrEditPersonnelContractsComponent,
                { id: id, userId: this.EmpId },
                {
                    width: '800px',
                }
            ).subscribe((res) => {
                if (res) {
                    this.getPersonnelContractsList();
                }
            });
        },
        /**
         * 删除用户合同
         */
        deletePersonnelContracts(record) {
            this.loading = true;
            this._personnelContractsServiceProxy
                .delete(record.id)
                .finally(() => {
                    this.loading = false;
                })
                .then(() => {
                    this.$notification['success']({
                        message: this.l('SuccessfullyDeleted'),
                    });
                    this.getPersonnelContractsList();
                });
        },
        /**
         * 获取社会关系列表
         */
        getSocialRelation() {
            this.loading = true;
            this._socialRelationServiceProxy
                .getListByUserId(this.EmpId)
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    this.SocialRelationTableData = res;
                });
        },
        /**
         *新增或编辑社会关系
         */
        createOrEditSocialRelation(id) {
            ModalHelper.create(
                CreateOrEditSocialRelation,
                { id: id, UserId: this.EmpId },
                {
                    width: '800px',
                }
            ).subscribe((res) => {
                if (res) {
                    this.getSocialRelation();
                }
            });
        },
        /**
         * 删除社会关系
         */
        deleteSocialRelation(record) {
            this.loading = true;
            this._socialRelationServiceProxy
                .delete(record.id)
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    this.$notification['success']({
                        message: this.l('SuccessfullyDeleted'),
                    });
                    // this.currentTabKey=2;
                    this.getSocialRelation();
                });
        },
        /**
         * 获取工作经验列表
         */
        getWorkExperience() {
            this.loading = true;
            this._workExperienceServiceProxy
                .getListByUserId(this.EmpId)
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    this.WorkExperienceTableData = res;
                    this.WorkExperienceTableData.map((item) => {
                        item.startDate = item.startDate ? moment(item.startDate).format('YYYY-MM-DD') : '-';
                        item.endDate = item.endDate ? moment(item.endDate).format('YYYY-MM-DD') : '-';
                    });
                });
        },
        /**
         * 新增或编辑工作经验
         */
        createOrEditWorkExperience(id) {
            ModalHelper.create(
                CreateOrEditWorkExperience,
                { id: id, UserId: this.EmpId },
                {
                    width: '800px',
                }
            ).subscribe((res) => {
                if (res) {
                    this.getWorkExperience();
                }
            });
        },
        /**
         * 删除工作经验
         */
        deleteWorkExperience(record) {
            this.loading = true;
            this._workExperienceServiceProxy
                .delete(record.id)
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    this.$notification['success']({
                        message: this.l('SuccessfullyDeleted'),
                    });
                    this.getWorkExperience();
                });
        },
        /**
         * 获取教育背景列表
         */
        getEducationBackground() {
            this.loading = true;
            this._ducationBackgroundServiceProxy
                .getListByUserId(this.EmpId)
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    this.EducationBackgroundTableData = res;
                    this.EducationBackgroundTableData.map((item) => {
                        item.startDate = item.startDate ? moment(item.startDate).format('YYYY-MM-DD') : '-';
                        item.endDate = item.endDate ? moment(item.endDate).format('YYYY-MM-DD') : '-';
                    });
                });
        },
        /**
         *新增或者编辑教育背景
         */
        createOrEditEducationBackground(id) {
            ModalHelper.create(
                CreateOrEditEducationBackground,
                { id: id, UserId: this.EmpId },
                {
                    width: '800px',
                }
            ).subscribe((res) => {
                if (res) {
                    this.getEducationBackground();
                }
            });
        },
        /**
         * 删除教育背景
         */
        deleteEducationBackground(record) {
            this.loading = true;
            this._ducationBackgroundServiceProxy
                .delete(record.id)
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    this.$notification['success']({
                        message: this.l('SuccessfullyDeleted'),
                    });
                    this.getEducationBackground();
                });
        },
        /**
         * 获取证书信息列表
         */
        getSkillInfo() {
            this.spinning = true;
            this._skillInfoServiceProxy
                .getListByUserId(this.EmpId)
                .finally(() => {
                    this.spinning = false;
                })
                .then((res) => {
                    this.SkillInfoTableData = res;
                    this.SkillInfoTableData.map((item) => {
                        item.expiryDate = item.expiryDate ? moment(item.expiryDate).format('YYYY-MM-DD') : '';
                        item.effectiveDate = item.effectiveDate ? moment(item.effectiveDate).format('YYYY-MM-DD') : '';
                    });
                });
        },
        /**
         * 新增或编辑证书信息
         */
        createOrEditSkillInfo(id) {
            ModalHelper.create(
                CreateOrEditSkillInfo,
                { id: id, UserId: this.EmpId },
                {
                    width: '800px',
                }
            ).subscribe((res) => {
                if (res) {
                    this.getSkillInfo();
                }
            });
        },
        /**
         * 删除证书信息
         */
        deleteSkillInfo(record) {
            this.spinning = true;
            this._skillInfoServiceProxy
                .delete(record.id)
                .finally(() => {
                    this.spinning = false;
                })
                .then((res) => {
                    this.$notification['success']({
                        message: this.l('SuccessfullyDeleted'),
                    });
                    this.getSkillInfo();
                });
        },
        beforeUpload(file) {
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
            if (!isJPG) {
                abp.message.error(this.l('OnlySupportPictureFile'));
            }
            const isLtXM = file.size / 1024 / 1024 < 2;
            if (!isLtXM) {
                abp.message.error(this.l('ProfilePicture_Warn_SizeLimit', 2));
            }
            return isJPG && isLtXM;
        },
        /**
         * 图片预览
         */
        async handlePreview(file) {
            if (!file.url && !file.preview) {
                file.preview = await ImageUtils.getBase64(file.originFileObj);
            }
            this.previewImage = file.url || file.preview;
            this.previewVisible = true;
        },
        /**
         * tab切换
         */
        tabChange(activeKey) {
            this.currentTabKey = activeKey;
            if (this.employee.id) {
                switch (this.currentTabKey) {
                    case 1:
                        //获取合同信息列表
                        this.getPersonnelContractsList();
                        break;
                    case 2:
                        //获取社会关系列表
                        this.getSocialRelation();
                        break;
                    case 3:
                        //获取工作经验列表
                        this.getWorkExperience();
                        break;
                    case 4:
                        //获取教育背景列表
                        this.getEducationBackground();
                        break;
                    case 5:
                        //获取证书信息
                        this.getSkillInfo();
                        break;
                }
            }
        },
        /**
         * 是否显示账号相关tab
         * @param e
         * @constructor
         */
        AccountChange(e) {
            this.employee.isCreateUser = e;
        },
    },
};
</script>

<style scoped></style>
