import { render, staticRenderFns } from "./basic-info.vue?vue&type=template&id=165d1d51&scoped=true&"
import script from "./basic-info.vue?vue&type=script&lang=js&"
export * from "./basic-info.vue?vue&type=script&lang=js&"
import style0 from "./basic-info.vue?vue&type=style&index=0&id=165d1d51&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "165d1d51",
  null
  
)

export default component.exports