<template>
	<a-spin :spinning="loading">
		<!--基本信息-->
		<div>
			<a-row class="q-mt-20">
				<!--公司、部门、岗位 工号 创建账号-->
				<a-col :span="12">
					<!--姓名-->
					<a-row style="margin-left: 40px">
						<a-col :span="4">
							<q-label required :label="l('姓名')"> </q-label>
						</a-col>
						<a-col :span="15">
							<a-input placeholder="请输入姓名" v-model="employee.realName" />
						</a-col>
					</a-row>
					<!--公司-->
					<a-row style="margin-left: 40px">
						<a-col :span="4">
							<q-label required :label="l('公司')"> </q-label>
						</a-col>
						<a-col :span="15">
							<a-tree-select
								class="left"
								@change="handleChange(1)"
								:placeholder="l('请选择公司')"
								style="width: 100%"
								allowClear
								tree-default-expand-all
								:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
								:tree-data="companyTreeList"
								v-model="employee.companyId"
							/>
						</a-col>
					</a-row>
					<!--工号-->
					<a-row style="margin-left: 40px">
						<a-col :span="4">
							<q-label required :label="l('工号')"> </q-label>
						</a-col>
						<a-col :span="7">
							<div v-if="type == 0">
								<div v-if="employee.id">
									{{ employee.jobNumber }}
								</div>
								<div v-else>
									<a-input placeholder="请输入工号" v-model="employee.jobNumber" />
								</div>
							</div>
							<div v-if="type == 1">
								<div v-if="issave && employee.jobNumber">
									{{ employee.jobNumber }}
								</div>
								<div v-else>
									<a-input placeholder="请输入工号" v-model="employee.jobNumber" />
								</div>
							</div>
						</a-col>
						<a-col :span="10" style="margin-left: 15px">
							<a-checkbox v-if="!user.id" @change="createAccount" :checked="employee.isCreateUser">
								创建账号
							</a-checkbox>
							<span v-else>
								{{ '账号：' + user.userName }}
							</span>
						</a-col>
					</a-row>

					<!--部门-->
					<a-row style="margin-left: 40px">
						<a-col :span="4">
							<q-label required :label="l('部门')"> </q-label>
						</a-col>
						<a-col :span="15">
							<a-tree-select
								v-model="employee.departmentId"
								style="width: 100%"
								show-search
								:filterTreeNode="searchTree"
								allowClear
								:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
								:tree-data="DepTreeData"
								:placeholder="l('请选择部门')"
								tree-default-expand-all
								@select="SelectDepartmentTree"
							>
							</a-tree-select>
						</a-col>
					</a-row>
					<!--岗位-->
					<a-row style="margin-left: 40px">
						<a-col :span="4">
							<q-label :label="l('岗位')"> </q-label>
						</a-col>
						<a-col :span="15">
							<a-tree-select
								v-model="employee.orgPostId"
								style="width: 100%"
								allowClear
								show-search
								:filterTreeNode="searchTree"
								:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
								:tree-data="orgPostTreeData"
								:placeholder="l('请选择岗位')"
								tree-default-expand-all
								@select="selectOrgPostTree"
							>
							</a-tree-select>
						</a-col>
					</a-row>
				</a-col>
				<!--证件照 姓名 入职日期-->
				<a-col :span="12">
					<!--证件照-->
					<a-row>
						<a-col :span="12" style="margin-left: 40px">
							<a-upload
								class="photo"
								listType="picture-card"
								:fileList="empFileList"
								:beforeUpload="beforeUpload"
								@preview="handlePreview"
								@change="handlePictureChange"
							>
								<div v-if="empFileList.length < 1">
									<div class="ant-upload-text">
										<a-icon type="plus" />
										<div class="ant-upload-text">{{ l('上传证件照') }}</div>
									</div>
								</div>
							</a-upload>
							<a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
								<img alt="example" style="width: 100%" :src="previewImage" />
							</a-modal>
						</a-col>
						<a-col :span="7" class="explain">
							<div class="explain-text" style="margin-left:-10px;margin-top:50px;width:150px">
								请上传一寸证件照<br />
								标准尺寸：<br />295px × 413px
							</div>
						</a-col>
					</a-row>
				</a-col>
			</a-row>
		</div>
		<!--账号信息-->
		<div v-if="!user.id && employee.isCreateUser" style="margin-left: 40px">
			<!--账号信息-->
			<a-row style="margin-top: 10px;">
				<a-col :span="12">
					<!--账号-->
					<a-row>
						<a-col :span="4">
							<q-label :label="l('账号')"> </q-label>
						</a-col>
						<a-col :span="14">
							<a-input placeholder="请输入账号" v-model="user.userName" />
						</a-col>
					</a-row>
				</a-col>
				<a-col :span="12">
					<!--密码-->
					<a-row>
						<a-col :span="6">
							<q-label :label="l('密码')"> </q-label>
						</a-col>
						<a-col :span="16">
							<div><a-input-password placeholder="默认密码:123456" v-model="user.password" /></div>
							<!--                            <div style="color: #8c8c8c;font-size: 10px">默认密码:123456</div>-->
						</a-col>
					</a-row>
				</a-col>
			</a-row>
		</div>
	</a-spin>
</template>
<!--基本信息组件-->
<script>
import {
	AppComponentBase,
	CompanyServiceProxy,
	DepartmentServiceProxy,
	EmployeeServiceProxy,
	OrgPostServiceProxy,
	UserEditDto,
	EmployeeEditDto,
} from '@/shared';
import { AppConsts } from '@/abpPro/AppConsts';
import ImageUtils from '@/shared/utils/image-utils';
import DepTreeUntils from '../../../../organization/department/dep-tree-untils/dep-tree-untils';

export default {
	name: 'basic-info',
	mixins: [AppComponentBase],
	props: {
		employee: {
			type: EmployeeEditDto,
			default: () => new EmployeeEditDto(),
		},
		user: {
			type: UserEditDto,
			default: () => new UserEditDto(),
		},
		type: {
			type: Number,
			default: undefined,
		},
		issave: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			//公司树
			companyTreeList: [],
			companyList: [],

			//部门树
			DepTreeData: [],
			DepTree: [],
			//岗位
			orgPostTreeData: [],
			orgPostTree: [],

			// 图片上传相关 ↓↓↓

			//用户头像
			userFilePath: `${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/UserPicture/${abp.session.tenantId}/`,
			//员工相关图片地址
			empFilePath: `${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/UserPicture/File/${abp.session.tenantId}/`,
			//是否显示图片预览框
			previewVisible: false,
			//预览图片地址
			previewImage: '',

			//证件照上传列表
			empFileList: [],

			jobNumber: undefined,
			// 图片上传相关 ↑↑↑
		};
	},
	created() {
		this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
		this._DepartmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
		this._orgPostServiceProxy = new OrgPostServiceProxy(this.$apiUrl, this.$api);
		this._employeeServiceProxy = new EmployeeServiceProxy(this.$apiUrl, this.$api);
	},
	async mounted() {
		if (this.employee.idPhoto) {
			ImageUtils.checkImage(this.empFilePath + this.employee.idPhoto)
				.then(() => {
					this.empFileList = [
						{
							uid: -1,
							name: this.employee.idPhoto,
							status: 'done',
							url: this.empFilePath + this.employee.idPhoto,
						},
					];
				})
				.catch(() => {
					this.empFileList = [
						{
							uid: -1,
							name: this.employee.idPhoto,
							status: 'done',
							url: '/assets/images/default/user.png',
						},
					];
				});
		}

		this.companyTreeInit();
		if (this.employee.isCreateUser && !this.employee.jobNumber && this.employee.companyId) {
			this.employee.isCreateUser = !this.employee.isCreateUser;
			let code = await this.getNewUserJobNumber();
			this.employee.jobNumber = code.newEmpJobNumber;

			this.createAccount(true);
			//赋值工号
			this.$emit('originalJobNumber', code);
		}
		//编辑时没有账号默认工号
		if (this.employee.isCreateUser && this.employee.jobNumber && !this.employee.userId) {
			this.user.userName = this.employee.jobNumber;
		}
	},
	methods: {
		companyTreeInit() {
			this.loading = true;
			this._CompanyConfigServiceProxy
				.getPaged(undefined, '', 100, 0)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.companyList = res.items;
					this.companyList.forEach((item) => {
						if (item.parentId == 0) {
							var obj = {
								key: item.id,
								value: item.id,
								title: item.fullName,
							};
							this.companyTreeList.push(obj);
						}
					});
					this.initCompanyTree(this.companyTreeList);
					//选中主页面选择的公司
					// this.employee.companyId = this.companyId;
					//选中主页面选择的部门
					if (this.depId) {
						// this.employee.departmentId = this.depId;
					}
					//主动触发公司选择
					this.handleChange();
				});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initCompanyTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.companyList.forEach((m) => {
					if (m.parentId == v.value) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id,
							title: m.fullName,
						};
						v.children.push(obj); //放进父级的集合中
						this.initCompanyTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		/**
		 * 选择公司
		 */
		async handleChange(type) {
			if (type) {
				this.employee.departmentId = undefined;
				this.employee.orgPostId = undefined;
			}

			if (this.employee.companyId) {
				this.getDepTreeData();
				this.getOrgPostTreeData();
				if (this.type == 0 && !this.employee.id) {
					let code = await this.getNewUserJobNumber();
					this.employee.jobNumber = code.newEmpJobNumber;
					this.user.userName = code.newEmpJobNumber;
					//赋值工号
					this.$emit('originalJobNumber', code);
				}
				if (this.type == 1) {
					let code = await this.getNewUserJobNumber();
					this.employee.jobNumber = code.newEmpJobNumber;
					//赋值工号
					this.$emit('originalJobNumber', code);
				}
			} else {
				this.employee.departmentId = undefined;
				this.employee.orgPostId = undefined;
				this.DepTreeData = [];
				this.orgPostTreeData = [];
			}
		},
		/**
		 * 根据公司ID获取随机工号
		 */
		getNewUserJobNumber() {
			return new Promise((resolve) => {
				this._employeeServiceProxy
					.getNewEmpJobNumber(this.employee.companyId)
					.finally(() => {
						this.loading = false;
					})
					.then(({ newEmpJobNumber, serialNumber }) => {
						resolve({ newEmpJobNumber, serialNumber });
					});
			});
		},
		/**
		 * 获取部门
		 */
		getDepTreeData() {
			this.loading = true;
			this.DepTreeData = [];
			this._DepartmentServiceProxy
				.getDepTreeData(this.employee.companyId)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.DepTree = DepTreeUntils.getInstance().InitSelectData(res);
					this.DepTree.forEach((item) => {
						if (!item.parentId) {
							this.DepTreeData.push(item);
						}
					});
					this.DepTreeData = DepTreeUntils.getInstance().ArrToTree(this.DepTree, this.DepTreeData, false);
				});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initDepTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.DepTree.forEach((m) => {
					if (m.parentId == v.key) {
						//根据主键查子集数据
						var obj = {
							key: m.key,
							value: m.key.toString(),
							title: m.title,
						};
						v.children.push(obj); //放进父级的集合中
						this.initDepTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		searchTree(inputValue, treeNode) {
			return treeNode.data.props.title.includes(inputValue);
		},
		/**
		 * 选择部门
		 */
		SelectDepartmentTree(e) {
			this.departmentId = e;
		},
		/**
		 * 获取岗位
		 */
		getOrgPostTreeData() {
			this.orgPostTreeData = [];
			this.loading = true;
			this._orgPostServiceProxy
				.getListByCompanyId(this.employee.companyId)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.orgPostTree = res;
					this.orgPostTree.forEach((item) => {
						if (!item.parentId) {
							let obj = {
								key: item.id,
								value: item.id.toString(),
								title: item.name,
							};
							this.orgPostTreeData.push(obj);
						}
					});
					this.orgPostTreeData = this.initOrgPostTree(this.orgPostTreeData);
				});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initOrgPostTree(list) {
			list.forEach((v) => {
				var c = this.orgPostTree.filter((t) => t.parentId == v.key);
				if (c.length > 0) {
					c = c.map((item) => {
						return {
							key: item.id,
							value: item.id.toString(),
							title: item.name,
						};
					});
					v.children = this.initOrgPostTree(c);
				}
			});
			return list;
		},
		selectOrgPostTree(e) {
			this.employee.orgPostId = e;
		},
		beforeUpload(file, fileList) {
			const isJPG =
				file.type === 'image/jpeg' ||
				file.type === 'image/png' ||
				file.type === 'image/gif' ||
				file.type === 'image/bmp';
			if (!isJPG) {
				abp.message.error(this.l('OnlySupportPictureFile'));
			}
			const isLtXM = file.size / 1024 / 1024 < 5;
			if (!isLtXM) {
				abp.message.error(this.l('ProfilePicture_Warn_SizeLimit', 2));
			}
			return false;
		},
		/**
		 * 证件照上传
		 */
		handlePictureChange({ file, fileList }) {
			this.empFileList = fileList;
		},
		/**
		 * 图片预览
		 */
		async handlePreview(file) {
			if (!file.url && !file.preview) {
				file.preview = await ImageUtils.getBase64(file.originFileObj);
			}
			this.previewImage = file.url || file.preview;
			this.previewVisible = true;
		},
		/**
		 * 创建账号勾选回调
		 * @param e
		 */
		createAccount(e) {
			this.employee.isCreateUser = !this.employee.isCreateUser;
			if (!this.employee.isCreateUser) {
				this.user.userName = undefined;
				this.user.password = undefined;
			} else {
				this.user.userName = this.employee.jobNumber;
				// this.user.password="123456";
			}
			//this.$emit("createAccountChange", this.employee.isCreateUser);
		},
	},
};
</script>

<style scoped>
.photo >>> .ant-upload-list-picture-card-container {
	width: 100%;
	height: 207px;
}

.photo >>> .ant-upload-list-picture-card .ant-upload-list-item {
	width: 168px;
	height: 225px;
}
.disabled {
	color: #333333;
	background-color: white;
	cursor: auto;
}
.photo >>> .ant-upload-select-picture-card {
	width: 150px;
	height: 207px;
}
</style>
