
import { AppComponentBase, EmployeeServiceProxy, EmployeeEditDto, OrgPostGradeServiceProxy } from '@/shared';
import Dic from '@/shared/utils/dic';
import moment from 'moment';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';

export default {
	name: 'work-info',
	mixins: [AppComponentBase],
	props: {
		employee: {
			type: EmployeeEditDto,
			default: () => new EmployeeEditDto(),
		},
	},
	data() {
		return {
			//员工类型字典
			dicEmpTypes: [],
			zh_CN,
			//员工状态字典
			dicEmpStates: [],
			//预转正日期
			preHireDate: '',
			//岗位等级列表
			listPostGrade: [],
			//招聘来源
			listrecruitmentSource: [],
			//劳务公司
			listpersonalService: [],
		};
	},
	async created() {
		try {
			this.loading = true;
			this.dicEmpTypes = await Dic.getInstance().getDicAsync('EmpType');
			this.dicEmpStates = await Dic.getInstance().getDicAsync('EmpState');
			this._orgPostGradeServiceProxy = new OrgPostGradeServiceProxy(this.$apiUrl, this.$api);
			if (this.employee.orgPostId) {
				this.listPostGrade = await this.getPostGradeListByPostId(this.employee.orgPostId);
			}
			this.listrecruitmentSource = await Dic.getInstance().getDicAsync('RecruitmentSource');
			this.listpersonalService = await Dic.getInstance().getDicAsync('PersonalService');
			if (this.employee.hireDate && this.employee.probationMonth) {
				let temp = moment(this.employee.hireDate).add(1, 'day');
				this.preHireDate = temp.add(this.employee.probationMonth, 'month').format('YYYY-MM-DD');
			}
			// this.checkEmpState();
		} catch (e) {
			console.error(e);
		} finally {
			this.loading = false;
			this.isReady = true;
		}
	},
	methods: {
		/**
		 * 试用期变更
		 */
		probationMonthChange() {
			const temp = moment(this.employee.hireDate).add(1, 'day');
			this.preHireDate = temp.add(this.employee.probationMonth, 'month').format('YYYY-MM-DD');
			this.employee.regularDate = moment(this.employee.hireDate).add(this.employee.probationMonth, 'month');
			// this.checkEmpState();
		},
		hireDateChange() {
			const temp = moment(this.employee.hireDate).add(1, 'day');
			this.preHireDate = temp.add(this.employee.probationMonth, 'month').format('YYYY-MM-DD');
			this.employee.regularDate = moment(this.employee.hireDate).add(this.employee.probationMonth, 'month');
			// this.checkEmpState();
		},
		/**
		 * 员工状态判定
		 */
		checkEmpState() {
			if (
				this.employee.regularDate < moment() &&
				(this.employee.probationMonth || this.employee.probationMonth == 0)
			) {
				this.employee.empStateCode = '01';
			} else if (
				this.employee.regularDate >= moment() &&
				(this.employee.probationMonth || this.employee.probationMonth == 0)
			) {
				this.employee.empStateCode = '04';
			}
		},
		/**
		 * 获取岗位等级列表
		 */
		getPostGradeListByPostId(e) {
			return new Promise((resolve) => {
				this._orgPostGradeServiceProxy
					.getPostGradeListByPostId(e)
					.then((res) => {
						resolve(res);
					})
					.catch(() => {
						resolve([]);
					});
			});
		},
	},
};
