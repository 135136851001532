class ObjectUtils {
	constructor() {
	}
	initDto(dto: any){
		for(let key of Object.keys(dto)){
			if(dto[key] === null){
				dto[key] = undefined
			}
		}
		return dto;
	}
}

export default new ObjectUtils();

